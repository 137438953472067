import { useState } from 'react'
import { Animated } from 'react-animated-css'
import { categoryToValue } from '../utils/sortHelper'

const SidebarItem = ({
  name,
  values = [],
  handleChangeInput,
  inputType,
  filters = {},
}) => {
  const [isOpen, setIsOpen] = useState(false)
  let checked

  if (inputType === 'radio') {
    checked = Object.keys(filters).map((x) => filters[x])
  } else {
    checked = values.map((x) => filters[x])
  }
  const handleClickHeader = () => {
    setIsOpen(!isOpen)
  }

  const handleChange = (e, index) => {
    handleChangeInput(
      categoryToValue[name],
      e.target.id,
      e.target.checked,
      index
    )
  }

  return (
    <div className="border-b-[1px] border-solid border-black px-2">
      <div
        onClick={handleClickHeader}
        className="flex justify-between items-center cursor-pointer py-4"
      >
        {name}
        <span
          className={`border-l-black w-0 h-0 border-[6px] border-solid transition-all border-transparent ${
            isOpen ? 'rotate-90' : 'rotate-0'
          }`}
        ></span>
      </div>
      {isOpen && (
        <Animated
          animationIn="fadeIn"
          animationInDuration={250}
          isVisible={isOpen}
        >
          <div className={`transition-all ${isOpen ? 'h-full' : 'h-0'}`}>
            {values.map((value, index) => {
              return (
                <label
                  className="flex justify-between items-center pb-2"
                  key={index}
                >
                  <div>{value}</div>
                  <input
                    className="border-black focus:ring-transparent focus:ring-0 h-3 w-3 checked:bg-black"
                    id={value}
                    checked={!!checked[index]}
                    onChange={(e) => {
                      handleChange(e, index)
                    }}
                    type={inputType}
                    name={name}
                  />
                </label>
              )
            })}
          </div>
        </Animated>
      )}
    </div>
  )
}

export default SidebarItem
