import { useState, useEffect, useMemo } from 'react';
import Sidebar from './components/SideBar';
import './App.css';
import { Outlet, useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import NftCard from './components/NftCard';
import MobileNav from './components/MobileNav';
import {
  valueToCategory,
  SORT_BY_ENUM,
  rarityLoHi,
  rarityHiLo,
} from './utils/sortHelper';

const App = ({ nfts, categories }) => {
  const traitTypes = useMemo(() => {
    return Object.keys(categories);
  }, [categories]);

  const navigate = useNavigate();
  const [appliedFilters, setAppliedFilters] = useState({});
  const [menuOpen, setMenuOpen] = useState(false);
  const [filteredNfts, setFilteredNfts] = useState(nfts);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState();
  useEffect(() => {
    const searchFilteredNfts = nfts.filter((nft) => {
      return nft.name.includes(searchTerm);
    });

    const fin = searchFilteredNfts.filter((nft) => {

      const nftAttributes = nft.attributes.reduce(
        (agg, x) => ({ ...agg, [x.trait_type]: x.value }),
        {}
      );

      return traitTypes.reduce((agg, x) => {

        return (
          agg &&
          (Object.keys(appliedFilters[x] || {}).length == 0 ||
            appliedFilters[x][nftAttributes[x]])
        );
      }, true);
    });

    // do sort
    let sortedFin = fin;
    switch (sortBy) {
      case 3:
        sortedFin = rarityHiLo(sortedFin);
        break;
      case 2:
        sortedFin = rarityLoHi(sortedFin);
        break;
      case 1:
        sortedFin.reverse();
        break;
      case 0:
      default:
        // default sort is name hi to lo
        break;
    }
    setFilteredNfts(sortedFin);
  }, [btoa(JSON.stringify(appliedFilters)), nfts.length, searchTerm, sortBy]);

  const handleSort = (categoryName, value, checked, index) => {
    if (categoryName === 'SORT BY' && checked) {
      setSortBy(SORT_BY_ENUM[value]);

      const updatedAppliedFilters = { ...appliedFilters };
      if (!updatedAppliedFilters[categoryName]) {
        updatedAppliedFilters[categoryName] = {};
      }

      const updatedCheckedState = new Array(4).fill(false);
      updatedCheckedState[index] = true;

      updatedAppliedFilters[categoryName] = updatedCheckedState;
      setAppliedFilters(updatedAppliedFilters);
    } else {
      const updatedAppliedFilters = { ...appliedFilters };
      if (!updatedAppliedFilters[categoryName]) {
        updatedAppliedFilters[categoryName] = {};
      }

      if (!checked) {
        delete updatedAppliedFilters[categoryName][value];
      } else {
        updatedAppliedFilters[categoryName][value] = checked;
      }

      if (!Object.keys(updatedAppliedFilters[categoryName]).length) {
        delete updatedAppliedFilters[categoryName];
      }

      setAppliedFilters(updatedAppliedFilters);
    }
  };

  const handleSearch = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const handleClickMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <Outlet />
      <MobileNav handleClickMenu={handleClickMenu} />
      <div className="flex relative z-10">
        <div
          className={`fixed bg-sand h-full w-full md:w-1/3 z-10 ${menuOpen ? 'block' : 'hidden'
            } md:block`}
        >
          <Sidebar
            handleSearch={handleSearch}
            handleChangeInput={handleSort}
            categories={categories}
            appliedFilters={appliedFilters}
          />
        </div>
        <div className="flex flex-col md:ml-[33vw] md:w-2/3 p-4 overflow-y-scroll h-[calc(100vh-40px)] md:h-screen mt-10 md:mt-0">
          <div className="flex justify-between mx-4 border-solid border-black border-b-[1px] pb-2">
            <div className="flex items-center">
              FILTERS APPLIED:{' '}
              {Object.keys(appliedFilters).reduce((agg, x) => {
                return (
                  agg +
                  (x == 'SORT BY' ? 0 : Object.keys(appliedFilters[x]).length)
                );
              }, 0)}
            </div>
          </div>
          <div className="flex flex-wrap m-2">
            {Object.keys(appliedFilters).length ? (
              <button
                key="clear-all"
                className="p-2 m-1 overflow-hidden text-ellipsis whitespace-nowrap bg-highlight-red text-white text-xs"
                onClick={() => {
                  setAppliedFilters({});
                }}
              >
                <div className="inline-block mr-2">X</div>
                Clear All
              </button>
            ) : null}
            {Object.keys(appliedFilters).map((filter) => {
              if (filter == 'SORT BY') return [];

              return Object.keys(appliedFilters[filter]).map((value, idx) => {
                return (
                  <button
                    key={`${filter}:${idx}`}
                    className="p-2 m-1 overflow-hidden text-ellipsis whitespace-nowrap bg-highlight-red text-white text-xs"
                    onClick={() => {
                      handleSort(filter, value, false);
                    }}
                  >
                    <div className="inline-block mr-2">X</div>
                    {value}
                  </button>
                );
              });
            })}
          </div>

          {nfts && (
            <div
              className={`p-4 grid gap-x-4 gap-y-12 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
            >
              {filteredNfts.map((nft, index) => {

                const id = parseInt(/\d+/.exec(nft.name));
                return (
                  <NftCard
                    handleClick={() => navigate(`/${id}`)}
                    imgSrc={nft.image}
                    id={id}
                    key={`${nft.name}:${index}`}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default App;
