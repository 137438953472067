import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import NFTModal from './routes/NFTModal'
import useNfts from './hooks/useNfts'

const root = ReactDOM.createRoot(document.getElementById('root'))

const Wrapper = () => {
  const { nfts, categories, traitRarities } = useNfts()

  return !nfts ? (
    'loading . . .'
  ) : (
    <BrowserRouter>
      <React.StrictMode>
        <Routes>
          <Route
            path="/"
            element={
              <App
                categories={categories}
                nfts={nfts}
                traitRarities={traitRarities}
              />
            }
          >
            <Route path=":id" element={<NFTModal />} />
          </Route>
        </Routes>
      </React.StrictMode>
    </BrowserRouter>
  )
}

root.render(<Wrapper />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
