import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import logo from '../assets/fLogo.png'
import hamburger from '../assets/hamburger.png'
import SidebarItem from './SideBarItem'
import { valueToCategory } from '../utils/sortHelper'
import { Animated } from 'react-animated-css'
import { SORT_BY_ENUM } from '../utils/sortHelper'

const Sidebar = ({
  handleChangeInput,
  handleSearch,
  categories,
  appliedFilters,
}) => {
  const sortedCategories = Object.keys(categories).sort((a, b) =>
    valueToCategory[a] > valueToCategory[b] ? 1 : -1
  )

  return (
    <Animated animationIn="fadeIn" animationInDuration={500}>
      <div className="flex flex-col m-6 w-[2/3] md:mr-[20%]">
        <div className="bg-highlight-red w-20 h-32 flex-col items-center justify-evenly hidden md:flex">
          <img src={logo} className="w-9" />
          <img src={hamburger} className="w-9" />
        </div>
        <div className="mt-10 text-lg font-black mb-2">FILTER</div>
        <div className="flex items-center border-solid border-black border-y-[1px] py-4 pr-2">
          <FontAwesomeIcon
            className="mr-2"
            icon={faMagnifyingGlass}
            size="sm"
          />
          <input
            className="bg-sand outline-none text-xs border-black w-full"
            type="text"
            placeholder="Search i.e. '6718'"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="overflow-y-auto h-[75vh] md:h-[60vh]">
          {sortedCategories.map((category, index) => {
            if (!valueToCategory[category]) return null
            return (
              <SidebarItem
                handleChangeInput={handleChangeInput}
                key={index}
                name={valueToCategory[category]}
                values={Object.keys(categories[category]).sort()}
                inputType="checkbox"
                filters={appliedFilters[category]}
              />
            )
          })}
          <SidebarItem
            name="SORT BY"
            values={Object.keys(SORT_BY_ENUM)}
            inputType="radio"
            handleChangeInput={handleChangeInput}
            filters={appliedFilters['SORT BY']}
          />
        </div>
      </div>
    </Animated>
  )
}

export default Sidebar
