import drifterName from '../utils/drifterName';
import { useOnScreen } from '../hooks/useOnScreen';
import { useRef } from 'react';
import Skeleton from 'react-loading-skeleton';

const NftCard = ({ id, handleClick }) => {
  const ref = useRef();
  const { frozen } = useOnScreen(ref, '45px', true);
  return (
    <div ref={ref}>
      {frozen ?
        <div
          onClick={handleClick}
          className="cursor-pointer flex flex-col items-center justify-center"
        >
          <img
            className="object-contain w-full"
            src={`https://omniscient.fringedrifters.com/main/web-images/${id}`}
          />
          <div className="text-xs mt-2">{drifterName(id)}</div>
        </div>
        :
        <Skeleton className='w-full aspect-square' />
      }
    </div>
  );
};

export default NftCard;
