export default {
  caddypack: '/backpacks/203',
  hunchback: '/backpacks/212',
  'gerry stow': '/backpacks/206',
  'poacher pots': '/backpacks/207',
  'squirrel pack': '/backpacks/201',
  'plant buddy': '/backpacks/208',
  'gutter shirt': '/suits/310',
  'the sink': '/backpacks/204',
  fancypack: '/backpacks/202',
  scrapper: '/backpacks/217, 218, 219',
  bobpack: '/backpacks/220',
  'little huffy': '/backpacks/221',
  smocker: '/suits/309',
  'kreegal cloak': '/suits/307',
  'grounder pounder': '/suits/308',
  'clincher x bincal': '/mouth-machines/130',
  'vayon worksuit': '/suits/303',
  'deverin jacket': '/suits/305',
  'pitchfork prongshell': '/suits/311',
  prongshell: '/suits/306',
  duggie: '/suits/301',
  platemail: '/suits/403',
  fursuit: '/suits/312',
  satchel: '/suits/318',
  thruster: '/tools-weapons/509',
  'the spunker': '/tools-weapons/504',
  'the shriek': '/tools-weapons/501',
  'round foot': '/tools-weapons/520',
  'hull breacher': '/tools-weapons/510',
  chainflicker: '/tools-weapons/502',
  wedge: '/tools-weapons/523',
  'death beam': '/tools-weapons/512',
  'reef sweeper': '/tools-weapons/515',
  'seam ripper': '/tools-weapons/505',
  'the (fat) koltso': '/tools-weapons/517',
  'everdull snippies': '/tools-weapons/516',
  'ball claw': '/tools-weapons/506',
  'the slipper': '/tools-weapons/513',
  'the koltso': '/tools-weapons/518',
  trunker: '/tools-weapons/514',
  'hole puncher': '/tools-weapons/519',
  'the nozzle': '/tools-weapons/522',
  'steady hammer': '/tools-weapons/508',
  'cream steamer': '/tools-weapons/525',
  flocker: '/tools-weapons/526',
  candlestick: '/tools-weapons/521',
  soaker: '/tools-weapons/524',
  soapy: '/tools-weapons/527',
  korkhead: '/helmets-masks/179',
  'bolt harp': '/tools-weapons/529',
  'cove carver': '/tools-weapons/530',
  "faulk's icepick": '/tools-weapons/528',
  roulette: '/tools-weapons/531',
  'the beetle': '/mouth-machines/145',
  deathshead: '/helmets-masks/125',
  'soup gagers': '/helmets-masks/126',
  'sock x charybdis': '/mouth-machines/180',
  'baghead x bincal': '/mouth-machines/181',
  'bc shiner': '/helmets-masks/102',
  muckface: '/mouth-machines/164',
  'bincal mouth machine': '/mouth-machines/144',
  'the sullken smile': '/mouth-machines/142',
  'kano headdress': '/helmets-masks/172',
  'good king maude': '/helmets-masks/170',
  'the saav': '/mouth-machines/146',
  hangtooth: '/helmets-masks/171',
  baghead: '/helmets-masks/162',
  yawper: '/helmets-masks/122',
  shutters: '/helmets-masks/123',
  sock: '/helmets-masks/161',
  'the hoodie': '/helmets-masks/101',
  'the bald beetle': '/mouth-machines/143',
  'the scaper': '/helmets-masks/166',
  'the stalker': '/helmets-masks/168',
  boxhead: '/helmets-masks/165',
  charybdis: '/mouth-machines/141',
  'dohead (pejorative)': '/helmets-masks/173',
  'backstab squeezebox': '/helmets-masks/167',
  "rustler's braincage": '/helmets-masks/169',
  'the clincher': '/helmets-masks/124',
  'puffy face': '/helmets-masks/175',
  'the channelrat': '/helmets-masks/127',
  astromask: '/helmets-masks/174',
  gloamwight: '/helmets-masks/128',
  darksight: '/helmets-masks/176',
  kipper: '/helmets-masks/103',
  'the wozer': '/helmets-masks/178',
  'house of ourbous': '/iconography/22',
  cathedral: '/iconography/10',
  mesmegraph: '/iconography/26',
  mercenary: '/iconography/25',
  'the forsaken': '/iconography/24',
  'glass womb': '/iconography/23',
  combustible: '/iconography/16',
  'the bench': '/iconography/21',
  'cove stalkers': '/iconography/19',
  deepsatch: '/iconography/18',
  'dugall freight': '/iconography/17',
  'machinist social club': '/iconography/15',
  'orbital miners guild': '/iconography/14',
  'the path': '/iconography/13',
  'dark ether house': '/iconography/12',
  'beshtala-chanko': '/iconography/11',
  gael: '/iconography/09',
  margul: '/iconography/08',
  lethal: '/iconography/06',
  'no damage': '/iconography/01',
  'pyramid order': '/iconography/48',
  'topography society': '/iconography/47',
  'cog crew': '/iconography/45',
  "freebooters' flag": '/iconography/44',
  'subsurface miners guild': '/iconography/43',
  'the borsh conservatory of gastrocraft': '/iconography/39',
  'mol bon tunnel': '/iconography/38',
  'round power': '/iconography/34',
  'survivalist guild': '/iconography/37',
  'league of channel': '/iconography/36',
  venonauts: '/iconography/35',
  carver: '/iconography/31',
  'roving terror': '/iconography/33',
  'free fringe': '/iconography/32',
  ji: '/iconography/30',
  slashpatcher: '/iconography/41',
  'saddler racing league': '/iconography/29',
  'union of merchants': '/iconography/40',
  wings: '/iconography/49',
  'grid gang': '/iconography/50',
  'cross of the round power': '/iconography/46',
  'high tox': '/iconography/28',
  'the man earlie expeditionary society': '/iconography/27',
  'beshtalan expatriation roster': '/iconography/20',
  'void drifter': '/iconography/07',
  hyperspec: '/iconography/05',
  'lighthouse sept': '/iconography/04',
  longhaulers: '/iconography/03',
  'the transcendentalists': '/iconography/02',
  'mark stay': '/iconography/51',
  'engineers guild': '/iconography/42',
  'isol marra': '/bibliography/isol-marra',
  'nelya gils': '/bibliography/nelya-gils',
  'vil sade': '/bibliography/vil-sade',
  'beshtala-chanko labor collective':
    '/bibliography/beshtala-chanko-labor-collective',
  'tridobal hippet': '/bibliography/tridobal-hippet',
  'fowes "the godbird" trent': '/bibliography/fowes-the-godbird-trent',
  'pettrik dilkie': '/bibliography/pettrik-dilkie',
  'fancy karl': '/bibliography/fancy-karl',
  'perse, the conservator': '/bibliography/perse-the-conservator',
  'bak tremons': '/bibliography/bak-tremons',
  'rav corver': '/bibliography/rav-corver',
  'slash patcher': '/iconography/41',
}
