import paths from '../data/urlMapping'

const Attribute = ({ name, value }) => {
  const path = paths[value.toLowerCase()]

  return (
    <div className="text-xs md:text-base flex items-center my-2">
      <div>{name}</div>
      <div className="h-2 flex-grow mx-2 dashed" />
      {path ? (
        <a
          target="_blank"
          href={`https://compendium.fringedrifters.com${path}`}
        >
          <div className="underline uppercase">{value}</div>
        </a>
      ) : (
        <div className="uppercase">{value}</div>
      )}
    </div>
  )
}

export default Attribute
