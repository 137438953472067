import logo from '../assets/fLogo.png'
import hamburger from '../assets/hamburger.png'
const MobileNav = ({ handleClickMenu }) => {

  return (
    <div
      className="fixed w-full z-50 block md:hidden"
    >
      <div className="bg-highlight-red w-full h-10 flex items-center justify-between px-6">
        <img src={logo} className="w-6" />
        <img onClick={handleClickMenu} src={hamburger} className="w-6" />
      </div>
    </div>
  )
}

export default MobileNav
