export const categoryToValue = {
  'HELMETS & MASKS': 'Headgear',
  'TOOLS & WEAPONS': 'Accessory',
  SUITS: 'Suit',
  BACKPACKS: 'Backpack',
  ICONOGRAPHY: 'Graphics',
  'SORT BY': 'SORT BY'
}

export const valueToCategory = {
  Headgear: 'HELMETS & MASKS',
  Accessory: 'TOOLS & WEAPONS',
  Suit: 'SUITS',
  Backpack: 'BACKPACKS',
  Graphics: 'ICONOGRAPHY',
  'SORT BY': 'SORT BY'
}

export const SORT_BY_ENUM = {
  "Name Low to High": 0,
  "Name High to Low": 1,
  "Rarity Low to High": 2,
  "Rarity High to Low": 3
}

export const rarityLoHi = (nfts) => {
  return nfts.sort((a, b) => {
    if (a.rarity > b.rarity) {
      return -1;
    }
    if (a.rarity < b.rarity) {
      return 1;
    }
    return 0;
  });
}

export const rarityHiLo = (nfts) => {
  return nfts.sort((a, b) => {
    if (a.rarity > b.rarity) {
      return 1;
    }
    if (a.rarity < b.rarity) {
      return -1;
    }
    return 0;
  });
}