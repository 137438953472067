import { useState, useEffect, useMemo } from 'react';

export default function useNfts() {
  const [nfts, setNfts] = useState([]);
  const [categories, setCategories] = useState({});
  const traitRarities = useMemo(() => {
    if (!nfts.length) return {};

    const traits = Object.keys(categories).flatMap((x) =>
      Object.keys(categories[x])
    );

    return traits.reduce((agg, trait) => {
      const rarity =
        nfts
          .map((x) => (x.attributes.find((x) => x.value == trait) ? 1 : 0))
          .reduce((agg, x) => agg + x) / nfts.length;

      return {
        ...agg,
        [trait]: rarity,
      };
    }, {});
  }, [categories]);

  function calculateRarity(nft) {
    return nft.attributes.reduce((agg, x) => {
      if (!traitRarities[x.value]) {
        return agg;
      }

      return agg + traitRarities[x.value];
    }, 0);
  }

  useEffect(() => {
    ; (async () => {
      const res = await fetch(
        'https://omniscient.fringedrifters.com/main/metadata/all'
      );
      const data = await res.json();

      setNfts(data);

      const traits = Object.keys(
        data.reduce((agg, x) => {
          const newAgg = { ...agg };

          for (let attr of x.attributes) {
            newAgg[attr.trait_type] = true;
          }

          return newAgg;
        }, {})
      );

      const computedCategories = {};

      for (let nft of data) {
        const attributes = nft.attributes.reduce(
          (agg, x) => ({ ...agg, [x.trait_type]: x.value }),
          {}
        );

        for (let trait of traits) {
          computedCategories[trait] = computedCategories[trait] || {};

          if (attributes[trait]) {
            computedCategories[trait][attributes[trait]] = true;
          }
        }
      }

      setCategories(computedCategories);
    })();
  }, []);

  useEffect(() => {
    if (!nfts.length || !Object.keys(traitRarities).length) return;

    setNfts((oldValue) => {
      return oldValue.map((x) => {
        return { ...x, rarity: calculateRarity(x) };
      });
    });
  }, [traitRarities]);

  return { nfts, categories, traitRarities };
}
