import { useEffect, useState, useRef } from 'react';
import { Animated } from 'react-animated-css';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';
import drifterName from '../utils/drifterName';
import parseTraits from '../utils/parseTraits';
import Attribute from './Attribute';

const traits = [
  { name: 'HELMET / MASK', value: 'Headgear', link: 'helmets-masks' },
  { name: 'TOOL / WEAPON', value: 'Accessory', link: 'tools-weapons' },
  { name: 'SUIT', value: 'Suit', link: 'suits' },
  { name: 'BACKPACK', value: 'Backpack', link: 'backpacks' },
  { name: 'ICONOGRAPHY', value: 'Graphics', link: 'iconography' },
  { name: 'LOCATION', value: 'Location' },
  { name: 'SANITY', value: 'Sanity' },
  { name: 'LOOKS RARE', value: 'Looks Rare' },
];

const NFTModal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [imageLoading, setImageLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [enlargedImage, setEnlargedImage] = useState(false);
  const intervalRef = useRef();

  useEffect(() => {
    ; (async () => {
      const res = await fetch(
        `https://omniscient.fringedrifters.com/main/metadata/${id}`,
        { mode: 'cors' }
      );
      setData(await res.json());
    })();
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setLoadingCounter((x) => (x + 1) % 4);
    }, 500);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const imageWidth = enlargedImage ? "w-full" : "w-480";
  return (
    <div
      className="cursor-pointer absolute h-full w-full left-0 top-0 z-20 overflow-auto"
      style={{ background: 'rgba(0, 0, 0, 0.4)' }}
      onClick={() => navigate('/')}
    >
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        animationInDuration={1000}
        animationOutDuration={1000}
        className="cursor-auto bg-mud min-h-2/3 my-[16.5vh] mx-auto w-fit max-w-[80vw]"
      >
        <div
          className="relative transition-all px-8 py-8 md:px-16 md:py-14 text-cream"
          onClick={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon
            className="hidden md:block ml-auto absolute md:top-4 md:right-8 cursor-pointer"
            icon={enlargedImage ? faCompress : faExpand}
            size="sm"
            onClick={() => setEnlargedImage(!enlargedImage)}
          />
          <FontAwesomeIcon
            className="ml-auto block absolute top-2 right-2 md:top-4 md:right-4 cursor-pointer"
            icon={faX}
            size="sm"
            onClick={() => navigate('/')}
          />
          <div className={imageLoading || !data ? 'invisible h-0' : null}>
            <div className={`md:${imageWidth}`}>
              <img
                className={`${imageWidth} ${imageLoading ? 'hidden' : 'block'}`}
                onLoad={() => {
                  setImageLoading(false);
                  clearInterval(intervalRef.current);
                }}
                src={`https://omniscient.fringedrifters.com/main/images/${id}.jpeg`}
              />
            </div>
            <div className="font-bold py-4 border-b">{drifterName(id)}</div>
            {data
              ? traits.map(({ name, value }, i) => {
                const traits = parseTraits(data.attributes);

                if (!traits[value]) return null;

                return (
                  <Attribute
                    key={i}
                    name={name}
                    value={traits[value].split(' - ')[0]}
                  />
                );
              })
              : null}
          </div>
          <div className={imageLoading || !data ? 'w-[80vw]' : 'invisible h-0'}>
            Loading {new Array(loadingCounter).fill('. ')}
          </div>

          <div className="mt-8 ml-auto w-fit flex items-center">
            <a
              className="inline-block"
              target="_blank"
              href={`https://opensea.io/assets/ethereum/0xe3b399aab015d2c0d787ecad40410d88f4f4ca50/${id}`}
            >
              <img
                className="ml-auto w-8"
                src="https://opensea.io/static/images/logos/opensea.svg"
              />
            </a>
            <a
              className="inline-block ml-4"
              target="_blank"
              href={`https://looksrare.org/collections/0xe3b399aab015d2c0d787ecad40410d88f4f4ca50/${id}`}
            >
              <img className="ml-auto w-8" src="/looksrare.png" />
            </a>
          </div>
        </div>
      </Animated>
    </div>
  );
};
export default NFTModal;
